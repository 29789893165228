import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PM_DOWN from '../../images/PM_animation/PM_DOWN.png'
import PM_UP from '../../images/PM_animation/PM_UP.png'


const useInterval = (handler, time) => {
    const [ intervalState, setIntervalState ] = useState(null)


    useEffect(() => {
        if(!intervalState){
            const myInterval = setInterval(handler, time)
            setIntervalState(myInterval)
        }
    },[intervalState])

    return [ intervalState, setIntervalState ]
}

class AnimationFrame{
    constructor(image){
        this.image = image
        this.next = null
        this.prev = null
    }
}



class AnimatedImages{
    constructor(imgs){
        this.rawImages = [...imgs]
        this.frameRate = 41
        this.animationFrames = null
        this.start = null
        this.end = null
    }
    addAll = () => {
        // Method that converts the 'imgs' array into the needed AnimationFrame type object 
        let arr = [...this.rawImages]
        this.animationFrames = new AnimationFrame(arr[0])
        this.start = this.animationFrames
        let current = this.animationFrames
        for (let i = 1 ; i < arr.length ; i++){
            current.next = new AnimationFrame(arr[i])
            current.next.prev = current
            if(i + 1 == arr.length){
                current.next.next = this.start
                this.end = current.next
                this.start.prev = this.end
            }else{
                current = current.next
            }
        }
    }
    step = () => {
        this.animationFrames = this.animationFrames.next
    }

}


const Animation = ({imgs}) => {

    const [ animationFrame, setAnimationFrame ] = useState(1)
    const [ isProcessed, setIsProcessed ] = useState(false)
    const [ animations, setAnimations ] = useState([])
    
    useEffect(() => {
        if(!isProcessed){
            let new_animations = imgs.map((img_arr) => {
                let newAnim =  new AnimatedImages(img_arr)
                newAnim.addAll()
                return newAnim
            })
            setAnimations(new_animations)
            setIsProcessed(true)
            console.log(new_animations);
            
        }
    },[isProcessed])

    const intervalFunc = () => {
        setAnimationFrame( state => state+1)
    }
    useInterval(intervalFunc, 41)

  return (
    <></>
  )
}
const IMG = styled.img`
    width: 100%;
`

const AnimationWrapper = styled.div`
    width: 163px;
    position: absolute;
    top: 45px;
    right: 48px;
    min-width: 163px;

    @media (max-width: 549px) {
        position: relative;
        top: initial;
        right: initial;
        transform: translate(50%, 23px);
    }

`

export default Animation;
