import ask from '../images/ask.png'
import gorby from '../images/gorby.png'
import gorby2 from '../images/gorby2.png'
import tennis1 from '../images/tennis__.mp4'
import pm_1 from '../images/priceMaster_1.mp4'
import taverne_tour_poster from "../images/show_posters/taverne_tour_2024.jpg"



const images = {
    'taverne_tour': [taverne_tour_poster],
    'nov7_2023': [pm_1],
    'oct6_2023': [tennis1],
    'eighth': [gorby],
    'seventh' : [gorby2],
    'sixth': [ask],
    'fifth': [],
    'fourth':
    [],
    
    'foufs':[
        `https://i.ibb.co/XXwJjzk/second-Show-32.jpg`,
        `https://i.ibb.co/GM78KjR/second-Show-31.jpg`,
        `https://i.ibb.co/XXwJjzk/second-Show-32.jpg`,
        `https://i.ibb.co/GM78KjR/second-Show-31.jpg`,
        `https://i.ibb.co/CsjJgPW/second-Show-30.jpg`,
        `https://i.ibb.co/Ycy8Kxn/second-Show-29.jpg`,
        `https://i.ibb.co/JKWmYFQ/second-Show-28.jpg`,
        `https://i.ibb.co/P6ZZgSK/second-Show-27.jpg`,
        `https://i.ibb.co/8DZK7b8/second-Show-26.jpg`,
        `https://i.ibb.co/svMFFRK/second-Show-25.jpg`,
        `https://i.ibb.co/FzKpdCm/second-Show-24.jpg`,
        `https://i.ibb.co/fvRHtQc/second-Show-23.jpg`,
        `https://i.ibb.co/N7TWMw4/second-Show-21.jpg`,
        `https://i.ibb.co/GJ0m9Ff/second-Show-22.jpg`,
        `https://i.ibb.co/j8cpZ5t/second-Show-20.jpg`,
        `https://i.ibb.co/nngZcZG/second-Show-19.jpg`,
        `https://i.ibb.co/vq8HyFn/second-Show-18.jpg`,
        `https://i.ibb.co/0qB6xdW/second-Show-17.jpg`,
        `https://i.ibb.co/K7mPQpp/second-Show-15.jpg`,
        `https://i.ibb.co/68PR6z7/second-Show-16.jpg`,
        `https://i.ibb.co/vcQz293/second-Show-14.jpg`,
        `https://i.ibb.co/7Cwd1QG/second-Show-13.jpg`,
        `https://i.ibb.co/k5NnQhW/second-Show-12.jpg`,
        `https://i.ibb.co/T8t9LVR/second-Show-11.jpg`,
        `https://i.ibb.co/hCfgzcc/second-Show-10.jpg`,
        `https://i.ibb.co/BNYSpVY/second-Show-9.jpg`,
        `https://i.ibb.co/QYhFgKy/second-Show-8.jpg`,
        `https://i.ibb.co/Qb43GQ0/second-Show-7.jpg`,
        `https://i.ibb.co/LStV7cz/second-Show-6.jpg`,
        `https://i.ibb.co/jbpTFSH/second-Show-5.jpg`,
        `https://i.ibb.co/7bwtFZL/second-Show-4.jpg`,
        `https://i.ibb.co/rsrh640/second-Show-3.jpg`,
        `https://i.ibb.co/mJnjNf0/second-Show-2.jpg`,
        `https://i.ibb.co/VMWcf2d/second-Show-1.jpg`,
    ],
    'first':[
        `https://i.ibb.co/ZJnJLRT/shunkchat-2.gif`,
        `https://i.ibb.co/NNF0yRj/shunkchat-3.gif`,
        `https://i.ibb.co/SsL74Yr/shunkchat-4.gif`,
        `https://i.ibb.co/9sp8FVy/shunkchat-5.jpg`,
        `https://i.ibb.co/tJtwwLV/shunkchat-6.jpg`,
        `https://i.ibb.co/c68bGBH/shunkchat-7.jpg`,
        `https://i.ibb.co/spSNm8W/shunkchat-8.jpg`,
        `https://i.ibb.co/2tzjNtm/shunkchat-9.jpg`,
        `https://i.ibb.co/Jykw5Tm/shunkchat-12.jpg`,
        `https://i.ibb.co/wcgzCQQ/shunkchat-13.jpg`,
        `https://i.ibb.co/HCwyvMJ/shunkchat-14.jpg`,
        `https://i.ibb.co/S794S94/shunkchat-15.jpg`,
        `https://i.ibb.co/SNfwX2w/shunkchat-16.jpg`,
        `https://i.ibb.co/1Z34kM1/shunkchat-17.jpg`,
        `https://i.ibb.co/s6Q23zn/shunkchat-18.jpg`,
        `https://i.ibb.co/PQKbk0k/shunkchat-19.jpg`,
        `https://i.ibb.co/kxT48Y9/shunkchat-20.jpg`,
        `https://i.ibb.co/KhhDhpV/shunkchat-21.jpg`,
        `https://i.ibb.co/WDPgJ8C/shunkchat-22.jpg`,
        `https://i.ibb.co/HnRrQhT/shunkchat-23.jpg`,
        `https://i.ibb.co/MBZsRzH/shunkchat-24.jpg`,
        `https://i.ibb.co/gwg59mQ/shunkchat-25.jpg`,
        `https://i.ibb.co/hy6QS9p/shunkchat-26.jpg`,
        `https://i.ibb.co/8YLz3ZL/shunkchat-27.jpg`,
        `https://i.ibb.co/LN7pHSK/shunkchat-28.jpg`,
        `https://i.ibb.co/7GJp6M2/shunkchat-29.jpg`,
        `https://i.ibb.co/ynFCrP9/shunkchat-30.jpg`,
        `https://i.ibb.co/LkT9Kz2/shunkchat-31.jpg`,
        `https://i.ibb.co/7SVCBsR/shunkchat-32.jpg`,
        `https://i.ibb.co/gdjdnn8/shunkchat-33.jpg`,
        `https://i.ibb.co/g7XDqJn/shunkchat-34.jpg`,
        `https://i.ibb.co/CnKRd9D/shunkchat-35.jpg`,
        `https://i.ibb.co/D9q0FxN/shunkchat-36.jpg`,
        `https://i.ibb.co/MGkq1bX/shunkchat-37.jpg`,
        `https://i.ibb.co/bHkvVND/shunkchat-38.jpg`,
        `https://i.ibb.co/Yjf4RNm/shunkchat-39.jpg`,
        `https://i.ibb.co/Xpfvnwt/shunkchat-40.jpg`,
        `https://i.ibb.co/vJ1jBCT/shunkchat-41.jpg`,
        `https://i.ibb.co/2jNHLjB/shunkchat-42.jpg`,
        `https://i.ibb.co/72k9LWg/shunkchat-43.jpg`,
        `https://i.ibb.co/fnnGKDt/shunkchat-44.jpg`,
        `https://i.ibb.co/wMRxsMR/shunkchat-45.jpg`,
        `https://i.ibb.co/YbBbpY2/shunkchat-46.jpg`,
        `https://i.ibb.co/6NtKTPB/shunkchat-47.jpg`,
        `https://i.ibb.co/tBp3CM2/shunkchat-48.jpg`,
        `https://i.ibb.co/WD01C2T/shunkchat-49.jpg`,
        `https://i.ibb.co/HGLM69J/shunkchat-50.jpg`,
        `https://i.ibb.co/p17tVsB/shunkchat-51.jpg`,
        `https://i.ibb.co/LPS1SW9/shunkchat-54.jpg`,
        `https://i.ibb.co/3pj0Yg6/shunkchat-55.jpg`,
        `https://i.ibb.co/6ZhGzY1/shunkchat-59.jpg`,
        `https://i.ibb.co/L9dks3d/shunkchat-60.jpg`,
        `https://i.ibb.co/P91DLvM/shunkchat-61.jpg`,
        `https://i.ibb.co/m0ycWsh/shunkchat-62.jpg`,
        `https://i.ibb.co/DRKJQVJ/shunkchat-63.jpg`,
        `https://i.ibb.co/r6RQX38/shunkchat-64.jpg`,
        `https://i.ibb.co/Ht17nXf/shunkchat-65.jpg`,
        `https://i.ibb.co/SXFMS80/shunkchat-66.jpg`,
        `https://i.ibb.co/VBLqvR6/shunkchat-67.jpg`,
        `https://i.ibb.co/FDCw2rY/shunkchat-68.jpg`,
        `https://i.ibb.co/2NmbDVz/shunkchat-69.jpg`,
        `https://i.ibb.co/y6KbPH8/shunkchat-70.jpg`,
        `https://i.ibb.co/4sZMQZ0/shunkchat-71.jpg`,
        `https://i.ibb.co/2FNBRfK/shunkchat-72.jpg`,
        `https://i.ibb.co/WKdjZgZ/shunkchat-73.jpg`,
        `https://i.ibb.co/PQz7hn8/shunkchat-74.jpg`,
        `https://i.ibb.co/jRMfzjv/shunkchat-75.jpg`,
        `https://i.ibb.co/R34k5DR/shunkchat-76.jpg`,
        `https://i.ibb.co/mCMvWhF/shunkchat-77.jpg`,
        `https://i.ibb.co/gyGxR5w/shunkchat-78.jpg`,
        `https://i.ibb.co/wzZfTTx/shunkchat-79.jpg`,
        `https://i.ibb.co/PDC21NV/shunkchat-80.jpg`,
        `https://i.ibb.co/QF7jnxV/shunkchat-81.png`,
        `https://i.ibb.co/fN6XwDm/shunkchat-82.png`,
        `https://i.ibb.co/5rBDwCp/shunkchat-83.png`,
        `https://i.ibb.co/xz1rd3C/shunkchat-84.png`,
        `https://i.ibb.co/Xy9fZDB/shunkchat-85.png`,
        `https://i.ibb.co/186kzMR/shunkchat-86.png`,
        `https://i.ibb.co/7VdjN6F/shunkchat-87.png`,
    ],
    'third':[
        `https://i.ibb.co/5BtJDJM/343979257-757951032656397-3120715629508874837-n.jpg`,
        `https://i.ibb.co/1n2yb6J/343725900-1393639054766447-3241603724291213735-n.jpg`,
        `https://i.ibb.co/stLmBgf/343988320-601828568543308-2723202703589974878-n.jpg`,
        `https://i.ibb.co/NFC9YHZ/344146840-172384302084331-6149799041359217082-n.jpg`,
        `https://i.ibb.co/4SvQkB5/344192207-152672021105889-7114631407663087921-n.jpg`,
        `https://i.ibb.co/BrMrrLH/344791943-817679596361600-7247956414104043813-n.jpg`,
        `https://i.ibb.co/gRQJYBT/344242880-604029055030451-1740203804106395008-n.jpg,
`    ],

}


export default images;