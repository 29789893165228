import shirt_1 from '../../images/merch/shirts/telephones/images/shirt_1.jpg'
import shirt_2 from '../../images/merch/shirts/telephones/images/shirt_2.jpg'
import shirt_3 from '../../images/merch/shirts/telephones/images/shirt_3.jpg'
import shirt_4 from '../../images/merch/shirts/telephones/images/shirt_4.jpg'
import shirt_5 from '../../images/merch/shirts/telephones/images/shirt_5.jpg'
import shirt_6 from '../../images/merch/shirts/telephones/images/shirt_6.jpg'
import shirt_7 from '../../images/merch/shirts/telephones/images/shirt_7.jpg'
import bear_1 from '../../images/merch/keychains/bear/images/bear_1.jpg'
import bear_2 from '../../images/merch/keychains/bear/images/bear_2.jpg'
import bear_3 from '../../images/merch/keychains/bear/images/bear_3.jpg'
import bear_4 from '../../images/merch/keychains/bear/images/bear_4.jpg'
import bear_5 from '../../images/merch/keychains/bear/images/bear_5.jpg'


// --------------------- Structs


class Shop_Item_Details{
    constructor(
        title,
        description,
        price,
        shipping_price,
        images,
        videos,
        sizes,
        versions,
        versionType,
        tags,
        links,
        item_type_id
        ){

        this.title = title
        this.description = description
        this.price = price
        this.shipping_price = shipping_price
        this.images = images
        this.videos = videos
        this.sizes = sizes
        this.versionType = versionType
        this.versions = versions
        this.tags = tags
        this.links = links
    }
}


class Shop_Item{
    constructor(type, details, id){
        this.type = type
        this.details = details
        this.id = id
    }
}

class Order_Item{
    constructor(
        item,
        quantity,
        color,
        extras
    ){
        this.item = item
        this.quantity = quantity || 1
        this.size = item.details?.sizes[0] || null
        this.color = color || null
        this.extras = extras || null
        
    }
}


///---------------------- Items



const DETAILS_Telephone_Shirt = new Shop_Item_Details(
    'Telephone T',
    `Hello? Hello...? Who is This? ... How did you get this number? 
    Hello?`,
    '25',
    0,
    [
        shirt_1,
        shirt_2,
        // shirt_3,
        // shirt_4,
        // shirt_5,
        shirt_6,
        // shirt_7 
    ],
    [],
    ['small', 'medium', 'large', 'XL'],
    '',
    [],
    ['shirt', 'telephone', 't-shirt', 'tshirt', 'wearable'],
    ['shunk.com'],
)


const DETAILS_Bear_Keychain = new Shop_Item_Details(
    'Bear Keychain',
    `🎵 Dingle Dangle, jingley jangle - a'ringly dingly dongly -dooo you have some honey, mate? 🎵 `,
    '5',
    0,
    [
        bear_1,
        bear_2,
        bear_3,
        bear_4,
        // bear_5,
    ],
    [],
    [],
    '',
    [],
    ['accessories', 'keychain', 'bear', 'misc', 'wearable'],
    ['shunk.com'],
)


const ITEM_Telephone_Shirt = new Shop_Item( 'shirt', DETAILS_Telephone_Shirt, "tele_sh1")
const ITEM_Bear_Keychain = new Shop_Item( 'keychain', DETAILS_Bear_Keychain, "bear_keych1")



const shop_item_tags = [
    'shirts',
    'sweaters',
    'accessories',
    'keychains',
    'CDs',
    'tapes',
    'tickets',
    'exclusives'
]

const items = [
    ITEM_Telephone_Shirt,
    ITEM_Bear_Keychain,
]

export { 
    ITEM_Telephone_Shirt,
    ITEM_Bear_Keychain,
    shop_item_tags,
    items,
    Order_Item
 } 