import React from 'react'
import styled from 'styled-components'

import RATKING_SNAKE_Polaroid from "../../images/secret/RATKING_SNAKE_Polaroid.png"
import RATKING from './DATA/audio/Shunk - LP - Odubs_RATKING LEAK_JAN-26.wav'
import Audio_Player from '../Audio_Player/Audio_Player'

const email = "shunkweh@gmail.com"

const NewYorkRatKing_Jan2024 = () => {

  const style_obj = { 'max-width': '80%'}

  return (
    <Wrapper>
      <ContentCenter>
        <RATKING_SNAKE_Polaroid_IMG src={RATKING_SNAKE_Polaroid}/>
        <H2> SHUNK - RATKING</H2>
        <Audio_Player src={RATKING} style_obj={style_obj}></Audio_Player>
        <HR/>
        <P>
          <H3>
            Stay in contact: 
          </H3>
          Send us an email with the subject: <br/>
          "Keep Me Shunked!" @ <br/> <a href={`mailto:${email}?subject=Keep Me Shunked!&body=Hi from New York City! `}>{email}</a> <br/>
          + Tell us about you!? <br/>
          <br/>
        </P>
      </ContentCenter>
    </Wrapper>
  )
}

const HR = styled.hr`
  color: black;
  width: 80%;
  margin: 20px 0 0 0 ;
`

const P = styled.p`
  text-align: center  ;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const H3 = styled.h3`
  text-align: center  ;
  margin-bottom: 10px;
  font-size: 25px;

`

const H2 = styled.h2`
`

const RATKING_SNAKE_Polaroid_IMG = styled.img`
  max-width: 400px;
  width: 68vw;
  min-width: 70%;
`

const ContentCenter = styled.div`
  position: relative;
  width: 68%;
  height: 80%;
  max-height: 800px;
  max-width: 500px;
  min-height: 750px;
  min-width: 300px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 40px;
  gap: 20px;
  border-radius: 3px;
`

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
`

export default NewYorkRatKing_Jan2024
