import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PM_DOWN from '../../../images/PM_animation/PM_DOWN.png'
import PM_UP from '../../../images/PM_animation/PM_UP.png'


const useInterval = (handler, time) => {
    const [ intervalState, setIntervalState ] = useState(null)


    useEffect(() => {
        if(!intervalState){
            const myInterval = setInterval(handler, time)
            setIntervalState(myInterval)
        }
    },[intervalState])

    return [ intervalState, setIntervalState ]
}


const PM_Animation = () => {

    const [ animationFrame, setAnimationFrame ] = useState(1)
    const intervalFunc = () => {
        setAnimationFrame( state => state+1)
    }
    useInterval(intervalFunc, 300)

  return (
    <Animation>
        
        {animationFrame % 4 != 0 && <IMG src={PM_DOWN} alt={''}></IMG>}
        {animationFrame % 4 == 0 && <IMG src={PM_UP} alt={''}></IMG>}
      
    </Animation>
  )
}
const IMG = styled.img`
    width: 100%;
`

const Animation = styled.div`
    width: 163px;
    position: absolute;
    top: 45px;
    right: 48px;
    min-width: 163px;

    @media (max-width: 549px) {
        position: relative;
        top: initial;
        right: initial;
        transform: translate(50%, 23px);
    }

`

export default PM_Animation;
