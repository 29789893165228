import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html body {
    min-height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    background-color: black;
    color: white;
    /* overflow-y: none; */
    overflow-x:hidden;

  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;
 
export default GlobalStyle;