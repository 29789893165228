import React, { createContext, useState, useContext } from "react";


export const ShopContext = createContext();


const tempCart = [
  // {
  //     name: '2',
  //     description: '',
  //     img: '',
  //     tags: '',
  //     sizes: [],
  // },
  // {
  //     name: '2',
  //     description: '',
  //     img: '',
  //     tags: '',
  //     sizes: [],
  // },
]

const ShopContextProvider = ({children}) => {


  const [ cartState, setCartState ] = useState(tempCart)
  


  return (
    <ShopContext.Provider
    value={
        {
            state: {
              cartState
            },
            action: {
              setCartState
            }
        }}
    >
      {children}
    </ShopContext.Provider>
  )
}

export default ShopContextProvider;
