import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import cloud_anim_1_1 from '../../../images/clouds/anim_1/1.png'
import cloud_anim_1_2 from '../../../images/clouds/anim_1/2.png'


const useInterval = (handler, time) => {
    const [ intervalState, setIntervalState ] = useState(null)

    useEffect(() => {
        if(!intervalState){
            const myInterval = setInterval(handler, time)
            setIntervalState(myInterval)
        }
    },[intervalState])

    return [ intervalState, setIntervalState ]
}


const CLOUDS_Animation_1 = ({top, left}) => {

    const [ animationFrame, setAnimationFrame ] = useState(1)
    const [ cloudAnimationFrame, setCloudAnimationFrame ] = useState(1)

    const intervalFunc = () => {
        setAnimationFrame( state => state+1)
    }
    useInterval(intervalFunc, 1500)


    const interval_cloudmove_Func = () => {
        setCloudAnimationFrame( state => state+1 )
    }
    useInterval(interval_cloudmove_Func, 10)


  return (
    <Animation style={{top: `${top}px`,left: `${left + (cloudAnimationFrame * 0.1)}px`}}>
        
        {animationFrame % 2 != 0 && <IMG style={{position: 'relative', left: '2px'}} src={cloud_anim_1_1} alt={''}></IMG>}
        {animationFrame % 2 == 0 && <IMG style={{position: 'relative', left: '2px'}} src={cloud_anim_1_2} alt={''}></IMG>}
      
    </Animation>
  )
}
const IMG = styled.img`
    width: 100%;
`

const Animation = styled.div`
    width: 163px;
    position: absolute;
    top: 200px;
    min-width: 163px;
    /* transition: left 1200ms; */
    /* transition-timing-function: linear; */


    /* @media (max-width: 549px) {
        position: relative;
        top: initial;
        right: initial;
        transform: translate(50%, 23px);
    } */

`

export default CLOUDS_Animation_1;
