import styled from "styled-components";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import images from "./images";



function isFileImage(file) {
    return file && file['type'].split('/')[0] === 'image';
}

const ShowPage = ()=> {


    // Get the userId param from the URL.
    let { which } = useParams();

    const [media, setMedia] = useState(undefined)
    const [render, setRender] = useState(false)


    const userAgent = navigator.userAgent;
    console.log(userAgent, 'useragent');



    useEffect(()=> {
        if(which){

            switch(which){
                case 'taverne_tour': 
                    setMedia(images['taverne_tour'])
                    return
                case 'nov7_2023':
                    setMedia(images['nov7_2023'])
                    return

                case 'oct6_2023':
                    setMedia(images['oct6_2023'])
                    return

                case 'eighth':
                    setMedia(images.eighth)
                    return
                // case 'eighth':
                //     setMedia(images.eighth)
                //     return

                case 'seventh':
                    setMedia(images.seventh)
                    return
        
                case 'sixth':
                    setMedia(images.sixth)
                    return

                case 'fifth':
                    setMedia(images.fifth)
                    return

                case 'fourth':
                    setMedia(images.fourth)
                    return
                    
                case 'third':
                    setMedia(images.third)
                    return

                case 'foufs':
                    setMedia(images.foufs)
                    return
                
                case 'first':
                    setMedia(images.first)
                    return
            }

        }
    }, [which])

    

    useEffect(()=> {
        if(media){
            setRender(true)
        }
    },[media])

    return (
        <ShowPageWrapper>

        
        {
            render ? <>


                {
                    which === 'nov7_2023' ? 
                    <>
                    {/* <H2 onClick={()=> {window.location.href = '/shop'}}>New SHUNK Merch</H2> */}
                    {/* <H2_2>New SHUNK Merch</H2_2> */}
                    {/* <H2_3>New SHUNK Merch</H2_3> */}
                    {/* <A href="/shop">Shop</A> */}
                    {/* <A_2 href="/shop">Shop</A_2> */}

                    <BIGVIDEO autoPlay={true} loop muted>
                        <source src={media} type="video/mp4"/>
                    </BIGVIDEO>
                    </> : <>
                    </>
                }
            
                {
                    which === 'oct6_2023' ? 
                    <BIGVIDEO autoPlay={true} loop muted>
                        <source src={media} type="video/mp4"/>
                    </BIGVIDEO> : <>
                    </>
                }
                {
                which === 'taverne_tour' ||
                which === 'eighth' || 
                which === 'seventh' ||
                which === 'sixth'

                
                ? <IMGMAP src={media}></IMGMAP> : <>
                
                {
                    media.map((item) => {                        

                        return (
                            // <>{item}</>
                        <IMG key={Math.random()*100000} src={item}></IMG>
                        )
                    })
                }</>

            } </> : <>loading...</>
            
        }
        {which === 'fifth' ? <>stay tuned... no photos yet</> : <></>}
        {which === 'fourth' ? <>stay tuned... no photos yet</> : <></>}

        </ShowPageWrapper>
    )
}

const A = styled.a`
    position: absolute;
    top: 45%;
    left: 11%;
    z-index: 10;
    font-size: 2rem;
    color: orange;
`
const A_2 = styled.a`
    position: absolute;
    top: calc(45% + 1px);
    left: calc(11% - 1px);
    z-index: 9;
    font-size: 2rem;
    color: black;
`
const H2 = styled.h2`
    position: absolute;
    color: rgba(240, 227, 60, 1);
    z-index: 10;
    font-size: 5rem;
    max-width: 300px;
    opacity: 0.8;
    top: 50%;
    left: 10%;
    cursor: pointer;


`
const H2_2 = styled.h2`
    position: absolute;
    color: rgba(181, 167, 18, 1);
    z-index: 9;
    font-size: 5rem;
    max-width: 300px;
    top: calc(50% - 1.1px);
    left: calc(10% - 1px);

`
const H2_3 = styled.h2`
    position: absolute;
    color: black;
    z-index: 8;
    font-size: 5rem;
    max-width: 300px;
    top: calc(50% + 3px);
    left: calc(10% + 4px);
    filter: blur(3px);
    opacity: 0.6;

`

const BIGVIDEO = styled.video`
    width: 100vw;
    position: absolute;
`

const IMGMAP = styled.img`
    width: 100vw;
`

const IMG = styled.img`
    height: 300px;
`


const ShowPageWrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;


`

export default ShowPage;