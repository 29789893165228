import React, { useContext } from 'react'
import styled from 'styled-components'

import { NavLink } from 'react-router-dom'

import { ShoppingCart } from 'react-feather'
import shopCart from '../../images/shop-cart.png'

import { ShopContext } from '../context/ShopContext'

const ShunkShop_CartWidget = () => {

  const shopContext = useContext(ShopContext)

  return (
    <ShunkShop_CartWidget_Button to="/shop" className="nodrag">
        <Styled_ShoppingCart color='white'/>
        {/* <IMG  src={shopCart} alt='' /> */}
      
    </ShunkShop_CartWidget_Button>
  )
}

export default ShunkShop_CartWidget

const Styled_ShoppingCart = styled(ShoppingCart)`
  width: 45%;
`

const ShunkShop_CartWidget_Button = styled(NavLink)`
    z-index: 10000;
    position: fixed;
    top: 20px;
    right: 38px;
    width: 70px;
    height: 70px;
    background-color: black;
    border-radius: 50%;
    border: none;
    /* background-image: ${shopCart}; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media (max-width: 549px) {
      top: 20px;
      right: 15px;
      width: 40px;
      height: 40px;
    }
`

const IMG = styled.img`

    width: 70%;
    height: 70%;
`