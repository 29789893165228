import styled from "styled-components";
import { NavLink } from "react-router-dom";


const Post = ({title, img, link, glow}) => {

    let glowingClass = glow ? "glow": ""


    return(
        <PostWrapper to={link}>

                <PostTitle className={glowingClass}>{title}</PostTitle>
                <PostContent>
                    <PostImg src={img} className={glowingClass}></PostImg>
                    {/* <NavLink to={'/club'}>hey</NavLink> */}
                </PostContent>

        </PostWrapper>
    )
}


export default Post;





const PostImg = styled.img`
    /* width: 30vw; */
    min-width: 70%;
    width: 120%;
    margin-top: 10px;
    

    &&.glow{
        @keyframes glowing {
            from {
                box-shadow:

                    0 0 6px 1px #0ff; /* outer cyan */
                }

            to {
                box-shadow:

                    0 0 60px 13px rgba(255,255,255,0.2); /* outer cyan */
                }
        }
        color: yellow;
        animation: glowing 5s infinite;
        animation-direction: alternate;
        z-index: -1;
    }
    z-index: 200;


`

const PostContent = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;


`

const PostTitle = styled.h2`
    &&.glow{
        color: yellow;
    }
`

const PostWrapper = styled(NavLink)`

    text-decoration: none;
    color: white;
    width: 100%;
    min-width: 300px;
    text-align: center;

    border-radius: 5px;
    margin-top: 10px;
    opacity: 10;
    margin-bottom: 50px;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    /* position: relative; */

    &&:hover{
        color: yellow
    }

`