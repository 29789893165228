import React from 'react'
import styled from 'styled-components'

import { NavLink } from 'react-router-dom'

import { Home } from 'react-feather'
import shopCart from '../../images/shop-cart.png'

const ShunkShop_HomeWidget = () => {
  return (
    <ShunkShop_HomeWidget_Button to="/" className="nodrag">
        <Styled_Home color='white'/>
        {/* <IMG  src={shopCart} alt='' /> */}
      
    </ShunkShop_HomeWidget_Button>
  )
}

export default ShunkShop_HomeWidget;


const Styled_Home = styled(Home)`
  width: 45%;
  
`

const ShunkShop_HomeWidget_Button = styled(NavLink)`
    z-index: 10000;
    position: fixed;
    top: 20px;
    left: 25px;
    width: 70px;
    height: 70px;
    background-color: black;
    border-radius: 50%;
    border: none;
    /* background-image: ${shopCart}; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media (max-width: 549px) {
      top: 20px;
      left: 15px;
      width: 40px;
      height: 40px;
    }
`

const IMG = styled.img`

    width: 70%;
    height: 70%;
`