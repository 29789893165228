
import Montreal_MX1_Cover from './Assets/images/TapeGhost_CD_size.png'

import MX1_INTRO from '../TapeGhostData/Assets/music/MX1/TAPEGHOST_INTRO.mp3'
import MX1_GoodShitShow_Sirens from '../../TapeGhost/TapeGhostData/Assets/music/MX1/1_GoodShitShow_CulturalCenter_Sirens_premix.mp3'
import MX1_SHUNK_RATKING from '../../TapeGhost/TapeGhostData/Assets/music/MX1/2_SHUNK_RATKING.mp3'
import MX1_UmmanManda_ExitNone from '../../TapeGhost/TapeGhostData/Assets/music/MX1/3_Umman Manda_ExitNone_premix.mp3'
import MX1_BadLuckJealousy_Socialite from '../../TapeGhost/TapeGhostData/Assets/music/MX1/4_BadLuckJealousy_Socialite.mp3'
import MX1_HipsOfLilith_WatchYou from '../../TapeGhost/TapeGhostData/Assets/music/MX1/5_HipsofLilith_WatchYou.mp3'
import MX1_Debris_Internal from '../../TapeGhost/TapeGhostData/Assets/music/MX1/6_Debris_Internal.mp3'
import MX1_GoodShitShow_ZeldaFreeJam from '../../TapeGhost/TapeGhostData/Assets/music/MX1/7_GoodShitShow_CulturalCenter_ZELDAFREEJAM.mp3'
import MX1_BadLuckJealousy_WakeUp from '../../TapeGhost/TapeGhostData/Assets/music/MX1/8_BadLuckJealousy_WakeUp.mp3'
import MX1_unknown_bates1demo3 from '../../TapeGhost/TapeGhostData/Assets/music/MX1/9_unkown_bates1demo3.mp3'
import MX1_LIQUIDATION_liquidate from '../../TapeGhost/TapeGhostData/Assets/music/MX1/10_LIQUIDATION_Liquidate_earlydemo.mp3'
import MX1_LIQUIDATION_PracticeSessionJan22 from '../../TapeGhost/TapeGhostData/Assets/music/MX1/11_LIQUIDATION_phonerecording_practicesessionjan22.mp3'
import MX1_BadLuckJealousy_Wintersong from '../../TapeGhost/TapeGhostData/Assets/music/MX1/12_BadLuckJealousy_Wintersong.mp3'
import MX1_OUTRO from '../../TapeGhost/TapeGhostData/Assets/music/MX1/TAPEGHOST_OUTRO.mp3'
import MX1_BadLuckJealousy_TerrysCar from '../../TapeGhost/TapeGhostData/Assets/music/MX1/BONUS_BadLuckJealousy_TerryCar.mp3'

export default {
    Montreal_MX1 :
    {
        COVER : Montreal_MX1_Cover,
        SONGS : [
            {
                NAME: 'INTRO',
                MP3 : MX1_INTRO
            },
            {
                NAME: 'Good Shit Show - Sirens',
                MP3 : MX1_GoodShitShow_Sirens
            },
            {
                NAME: 'SHUNK - RATKING',
                MP3 : MX1_SHUNK_RATKING
            },
            {
                NAME: 'Umman Manda - Exit None',
                MP3 : MX1_UmmanManda_ExitNone
            },
            {
                NAME: 'Bad Luck Jealousy - Socialite',
                MP3 : MX1_BadLuckJealousy_Socialite
            },
            {
                NAME: 'Hips Of Lilith - Watch You',
                MP3 : MX1_HipsOfLilith_WatchYou
            },
            {
                NAME: 'Debris - Internal',
                MP3 : MX1_Debris_Internal
            },
            {
                NAME: 'Good Shit Show - (ZeldaJam)',
                MP3 : MX1_GoodShitShow_ZeldaFreeJam
            },
            {
                NAME: 'Bad Luck Jealousy - Wake Up',
                MP3 : MX1_BadLuckJealousy_WakeUp
            },
            {
                NAME: '????????? - bates1_demo_3',
                MP3 : MX1_unknown_bates1demo3
            },
            {
                NAME: 'LIQUIDATION - Liquidate',
                MP3 : MX1_LIQUIDATION_liquidate
            },
            {
                NAME: 'LIQUIDATION - Practice Session Jan22',
                MP3 : MX1_LIQUIDATION_PracticeSessionJan22
            },
            {
                NAME: 'Bad Luck Jealousy - Wintersong',
                MP3 : MX1_BadLuckJealousy_Wintersong
            },
            {
                NAME: 'OUTRO',
                MP3 : MX1_OUTRO
            },
            {
                NAME: "BONUS - Bad Luck Jealousy - Terry's Car",
                MP3 : MX1_BadLuckJealousy_TerrysCar
            }
        ]
    }
}