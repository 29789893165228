import React from 'react'

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


const Audio_Player = ({src, style_obj, song_index, songState, setSongState}) => {

  const is_paused = songState === song_index ? true : false

  return (
    <AudioPlayer
    onPlay={e => { setSongState(song_index)} }
    
    autoPlay={false}
    src={src}
    style={style_obj}

    // other props here
  />
  )
}

export default Audio_Player
