import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import Audio_Player from '../../Audio_Player/Audio_Player' 
import Tapeghost_Data from './TapeGhostData/Tapeghost_Data'

const email = "tapeghost.mxs@gmail.com"


const PlayerCard = ({songState, setSongState, song_index, SongData}) => {
  return (
    <PlayerCardWrapper>
      {SongData.NAME}
      <Audio_Player songState={songState} setSongState={setSongState} song_index={song_index} src={SongData.MP3}></Audio_Player>
    </PlayerCardWrapper>
  )
}

const PlayerCardWrapper = styled.div`
  width: calc(100% - 4px);
  height: 100px;
  background-color: white;
  margin: 2px 2px 10px 2px;

`

const TapeGhost = () => {

    const PARAMS = useParams()
    const MX = PARAMS.MX
    const SONGS = Tapeghost_Data[MX].SONGS

    const [ songState, setSongState ] = useState(null)
    
  return (
    <Wrapper>
        <ContentCenter>
        { 
            Tapeghost_Data[MX] ? 
            
            <>
              <IMG_CONTAINER>
                <TAPEGHOST_COVER_IMG src={Tapeghost_Data[MX].COVER}/>
              </IMG_CONTAINER>
                <About>
                  TapeGhost <Span>MX1</Span> is a collection of recordings from Montreal's <br/> (Post Punk, Indie, Lo-fi, Prog, Shoegaze...) <br/> <A target="_blank" href={'https://www.google.com/search?q=mile-end+montreal&tbm=isch&chips=q:mile+end+montreal,g_1:map:2RvLgUjtuwU%3D&hl=en&sa=X&ved=2ahUKEwi8vpjl14CEAxXyOVkFHU6zDYQQ4lYoAXoECAEQNg&biw=1222&bih=992'}>Mile-End</A> Music Scene 
                </About>
                <About>
                  <Span>MX1</Span> includes releases and unmixed/demo tracks from local projects that <br/>TapeGhost <Span>loves</Span>.
                </About>
                {/* </P>
              <P>  */}
              <SongsScroll>
                {
                  SONGS.map( (songData, song_index) => {
                    return (
                      <PlayerCard songState={songState} setSongState={setSongState} song_index={song_index} SongData={songData}></PlayerCard>
                    )
                  })
                }
              </SongsScroll>
              <HR/>
              <P>
                <H3>
                  Stay in Contact: 
                </H3>
                <>
                Send us an email with<br/>"TAPEGHOST" in the subject line <br/>@<br/>
                </>
                <a href={`mailto:${email}?subject=TAPEGHOST&body=Hi from New York City! I want to keep updated updated with TAPEGHOST `}>{email}</a>
                {/* <br/> */}
                <P_small>
                  If you want to keep updated on any of the bands <br/> or the TAPEGHOST Mixtapes Project
                </P_small>
              </P>
            </>
            :
            <>... error ... ?</>
        }
        </ContentCenter>
    </Wrapper>
  )
}
const Span = styled.span`
  @keyframes colorchange {
    0% {color: red;}
    20% {color: lime;}
    40% {color: purple;}
    60% {color: magenta;}
    80% {color: darkcyan;}
  }

  /* The element to apply the animation to */
  width: 100px;
  height: 100px;
  font-size: large;
  font-weight: 900;
  font-style: italic;
  animation: colorchange 5s infinite alternate;
`

const About = styled.p`
  text-align: center  ;
  width: 80%;
  /* max-width: 80%; */
  font-size: 18px;
  margin-bottom: 2px;
  /* color: purple; */
`


const A = styled.a`
  display: inline-block;
`

const HR = styled.hr`
  color: black;
  width: 100%;
`

const P_small = styled.p`
  text-align: center  ;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  /* max-width: 80%; */
  font-size: 13px;
  margin-bottom: 2px;
  color: purple;
`

const P = styled.p`
  position: relative;
  text-align: center  ;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  /* width: 250px; */

  gap: 2px;
`

const H3 = styled.h3`
  text-align: center;
  width: 80%;
  font-size: 25px;
  margin-bottom: 8px;

`

const IMG_CONTAINER = styled.div`
  width: 80%;
  aspect-ratio: 1 / 0.5;
  /* width: 100%; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`


const SongsScroll = styled.div`
  width: 80%;
  flex-grow: 1;
  overflow: scroll;
  background-color: grey;
  height: 500px;
  border: 2px grey solid;
  /* padding: 0 3%; */
`

const TAPEGHOST_COVER_IMG = styled.img`
  width: 100%;
  flex-grow: inherit;
  

`

const ContentCenter = styled.div`
  position: relative;
  width: 68%;
  height: 80%;
  max-height: 800px;
  max-width: 500px;
  min-height: 2300px;
  min-width: 300px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  border-radius: 3px;
  padding: 40px 10px;
`


const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
`

export default TapeGhost
