import styled from "styled-components";


const BandPage = () => {

    return (
        <BandPageWrapper></BandPageWrapper>
    )
}


const BandPageWrapper = styled.div`
    width: 100vw;
    height: 100vh;

`
export default BandPage;